import * as firebase from 'firebase/app'
import 'firebase/messaging';

let messaging = null
if(firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp({
          apiKey: "AIzaSyB6kkTsOeo-MdHf8AFGId_VHR5eKt5yoQ0",
          authDomain: "wndw-fcm.firebaseapp.com",
          projectId: "wndw-fcm",
          storageBucket: "wndw-fcm.appspot.com",
          messagingSenderId: "196982874657",
          appId: "1:196982874657:web:338c0e1ff3bc698b4e7960",
          measurementId: "G-Y3LSBMG92E"
    })

    messaging = initializedFirebaseApp.messaging();

    messaging.usePublicVapidKey(
        "BAJOTYoeJiXtRluvbawtgIeWDNtQoYmmL4tpusv4zJPnXh7C5aNAIU04F-so2WiAnqfNrcepHA5TxiEEwF5LYCE"
    )
}

export { messaging }