
let host=location.host
var baseURL='';
if(host=='vendor.wndw.in'){
     baseURL = "https://admin.wndw.in/api";
     console.log = function() {}
}
else{
  
  baseURL= "https://admin-sa.wndw.in/api";
  // baseURL = "http://127.0.0.1:8000/api";
}

export {baseURL};
